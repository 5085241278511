import {lazy} from "react";

const MenuRoutes = [
    {
        path: "/",
        exact: true,
        name: '首页',
        component: lazy(() => import("../view/Home")),
        icon: 'icon-icon'
    },
    {
        path: "/imgBed",
        name: '图床',
        component: lazy(() => import("../view/ImgBed")),
        icon: 'icon-sort'
    },
    {
        path: "/link",
        name: '友链',
        component: lazy(() => import("../view/link/LinkList")),
        icon: 'icon-sort'
    },
    {
        path: "/users",
        name: "所有用户",
        component: lazy(() => import("../view/user/UserList")),
        icon: 'icon-wenzhang_huaban'
    },
    {
        path: "/sort",
        name: '分类',
        component: lazy(() => import("../view/SortPage")),
        icon: 'icon-sort'
    },
    {
        path: "/tag",
        name: "标签",
        component: lazy(() => import("../view/TagPage")),
        icon: 'icon-tags'
    },
    {
        path: "/article",
        name: "文章",
        component: lazy(() => import("../view/ArticlePage")),
        icon: 'icon-wenzhang_huaban'
    },
    {
        path: "/createArticle",
        name: "创建文章",
        component: lazy(() => import("../view/AddOrEditArticlePage")),
        hidden: true
    },
    {
        path: "/settings/oss",
        name: "OSS设置",
        component: lazy(() => import("../view/settings/OSSEdit")),
        icon: 'icon-wenzhang_huaban'
    },
];

export default MenuRoutes;
