import {configureStore} from '@reduxjs/toolkit';
import UserSlice from "./UserSlice";

const store = configureStore({
    reducer: {
        counter: UserSlice.reducer,
    },
});
export default store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
