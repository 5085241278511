import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";

export default function useStore() {
    const dispatch = useDispatch<AppDispatch>();
    const selector: TypedUseSelectorHook<RootState> = useSelector;
    return {
        dispatch, selector
    }
}


