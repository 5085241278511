import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import RouterItem from "../interfaces/RouterItem";
import useUser from "../hooks/useUser";
import {getQueryAll} from "../utils/utils";
import SetTitle from "../Hoc/SetTitle";
import MenuRoutes from "./MenuRoutes";

const MenuRouter: React.FC = () => {
    const {isLogin} = useUser();
    const loopRoute = (route: RouterItem) => {
        if (isLogin) {
            return (
                <Route
                    exact
                    key={route.path}
                    path={route.path}
                    render={(props: RouteComponentProps<any>) => {
                        let queries = getQueryAll();
                        let queryToProps = {...props, queries}
                        return <SetTitle title={route.name} component={route.component} {...queryToProps}/>
                    }}
                >
                </Route>
            )
        } else {
            return (<Redirect key={route.path} to={'login'}/>)
        }
    }
    const createMenuRoutes = () => {
        return MenuRoutes.map(loopRoute)
    }

    return (
        <Switch>
            {createMenuRoutes()}
        </Switch>
    )
}

export default MenuRouter;
