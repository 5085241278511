import {Button, Form, Input} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import React, {useState} from "react";
import PropsRoute from "../interfaces/PropsRoute";
import useStore from "../hooks/useStore";
import LoginReqDto from "../interfaces/dto/LoginReqDto";
import UserApi from "../api/UserApi";
import msg, {msgErr} from "../utils/msg";
import {setToken, setUser} from "../store/UserSlice";

const Login: React.FC<PropsRoute> = (props: PropsRoute) => {
    const [loading, setLoading] = useState(false);
    const {dispatch} = useStore();
    const onFinish = (values: LoginReqDto) => {
        setLoading(true)
        UserApi.login(values).then(async (res) => {
            if (res.code === 0) {
                await msg(res.message);
                dispatch(setToken(res.data));
                UserApi.get().then(async (res) => {
                    dispatch(setUser(res.data));
                    props.history.push('/');
                })
            } else {
                await msgErr(res.message);
            }
        }).finally(() => {
            setLoading(false)
        })
    };

    return (
        <div className="login-main">
            <Form
                name="normal_login"
                className="login-form"
                onFinish={onFinish}
            >
                <Form.Item name="username" rules={[{required: true, message: '请输入用户名',}]}>
                    <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="用户名"/>
                </Form.Item>
                <Form.Item name="password" rules={[{required: true, message: '请输入密码'}]}>
                    <Input prefix={<LockOutlined className="site-form-item-icon"/>} type="password" placeholder="密码"/>
                </Form.Item>
                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                        登录
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Login;
