import React, {useEffect} from "react";
import {Layout, Menu} from 'antd';
import Logo from "./Logo";
import {MenuInfo} from "rc-menu/es/interface";
import MenuRoutes from "../router/MenuRoutes";
import RouterItem from "../interfaces/RouterItem";
import IconFont from "../components/IconFont";
import PropsRoute from "../interfaces/PropsRoute";
import styles from "./styles/aside.module.scss"
const {Sider} = Layout;

const Aside: React.FC<PropsRoute> = (props: PropsRoute) => {
    const [selectedKey, setSelectedKey] = React.useState("");
    const skip = (info: MenuInfo): void => {
        props.history.push(info.key as string)
    }
    useEffect(() => {
        setSelectedKey(props.location.pathname)
    }, [])
    const menuClick = (info: MenuInfo) => {
        setSelectedKey(info.key as string)
    };
    const Menus = MenuRoutes.map((item: RouterItem) => {
            if (!item.hidden) {
                return (
                    <Menu.Item key={item.path} icon={<IconFont type={item.icon as string}/>} onClick={skip}
                               data-url={item.path}>
                        {item.name}
                    </Menu.Item>
                )
            }
        }
    );
    return (
        <Sider className={styles.aside} theme={'light'} trigger={null} >
            <Logo/>
            <Menu theme={"light"} mode="inline"
                  onClick={menuClick}
                  selectedKeys={[selectedKey]}
            >
                {Menus}
            </Menu>
        </Sider>
    )
}


export default Aside;
