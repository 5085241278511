import {useMemo} from "react";
import useStore from "./useStore";
import {RootState} from "../store/store";
import cache from "../utils/cache";

export default function useUser() {
    const {selector} = useStore();
    const token = selector((state: RootState) => state.counter.token);
    const user = selector((state: RootState) => state.counter.user);
    const isLogin = useMemo(() => {
        return token !== undefined || cache.get("token") !== null;
    }, [token])
    return {
        token, user, isLogin
    }
}
