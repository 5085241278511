import React, {useEffect} from "react";
import {RouteComponentProps} from "react-router";

interface setTitleProps extends RouteComponentProps {
    title: string,
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const SetTitle: React.FC<setTitleProps> = (props: setTitleProps) => {
    let Component = props.component;
    useEffect(() => {
        document.title = props.title
    }, [props.title])

    return (
        <Component {...props}/>
    )
}


export default SetTitle;
