import React from "react";
import styles from "./styles/logo.module.scss";

const Logo: React.FC = () => {
    return (
        <div className={styles.logo}>
            Plum Blog
        </div>
    )
};

export default Logo;
