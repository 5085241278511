import req from "../utils/request/req";
import IPage from "../interfaces/IPage";
import {construct} from "../utils/utils";
import LoginReqDto from "../interfaces/dto/LoginReqDto";
import User from "../interfaces/entity/User";

export class UserApiImpl {
    page = (page: number | string, size: string | number) => {
        return req.get<IPage<User>>({
            url: `article/${page}/${size}`
        })
    }
    // get = (id: number | string,) => {
    //     return req.get({
    //         url: `user/${id}`
    //     })
    // }
    get = () => {
        return req.get<User>({
            url: `user`
        })
    }
    login = (data: LoginReqDto) => {
        return req.post<string>({
            url: `user/login`,
            data
        })
    }
}

export default construct<UserApiImpl>(UserApiImpl);
