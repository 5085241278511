import React, {Suspense} from "react";
import {Layout as AntdLayout} from 'antd';
import {withRouter} from "react-router";
import PropsRoute from "../interfaces/PropsRoute";
import MenuRouter from "../router/MenuRouter";
import Header from "./Header";
import Aside from "./Aside";
import styles from "./styles/layout.module.scss";
const {Sider, Content} = AntdLayout;

const Layout: React.FC<PropsRoute> = (props: PropsRoute) => {
    return (
        <AntdLayout className={styles.layout}>
            {/*// @ts-ignore*/}
            <Aside {...props}/>
            <AntdLayout className={styles.main}>
                <Header/>
                <main className={styles.layout_content}>
                    <Suspense fallback={<div>Loading......</div>}>
                        <MenuRouter/>
                    </Suspense>
                </main>
            </AntdLayout>
        </AntdLayout>
    )
}


export default withRouter(Layout);
