import React from "react";

import {Layout, Menu,} from 'antd';
import styles from "./styles/header.module.scss";

const {SubMenu} = Menu;
const {Header: AntdHeader} = Layout;

const Header: React.FC = () => {

    const title = (
        <div className="avatar">
            {/*<img alt="头像"/>*/}
            Admin
        </div>
    )

    return (
        <AntdHeader className={styles.header}>
            <Menu className={styles.menu} mode="horizontal">
                <SubMenu key={1} title={title}>
                    <Menu.Item key={1}>退出登录</Menu.Item>
                </SubMenu>
            </Menu>
        </AntdHeader>
    )
}

export default Header;
