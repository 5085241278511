import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import User from "../interfaces/entity/User";
import cache from "../utils/cache";

interface UserState {
    token: undefined | string;
    user: undefined | User;
}

const initialState: UserState = {
    token: undefined,
    user: undefined,
};
const UserSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            cache.set("user", state.user)
        },
        removeUser: state => {
            state.user = undefined;
            cache.remove("user")
        },
        removeToken: state => {
            state.token = undefined;
            cache.remove("token")
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
            cache.set("token", state.token)
        },
    },
});
export const {setUser, removeUser, removeToken, setToken} = UserSlice.actions
export default UserSlice;
